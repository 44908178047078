html,
body,
#root {
  height: 100%;
}

#root > .router {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.jp-card .jp-card-front,
.jp-card .jp-card-back {
  background: #aaa;
}

.fileUploader {
  border: none;
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  margin-top: 10px;
}

.fileContainer {
  background: transparent;
  box-shadow: none;
  position: relative;
  border-radius: 4px;
  border: 1px solid #989898;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all 0.3s ease-in;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
}

.fileContainer p:empty {
  font-size: 12px;
  margin: 0;
}

.fileContainer .uploadPicturesWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.fileContainer .uploadPicturesWrapper > div {
  width: 100%;
  height: 100%;
}

.uploadPicturesWrapper > div:empty {
  height: 1px;
}

.fileContainer .uploadPictureContainer {
  height: 100%;
  width: 100%;
  background: rgb(255, 255, 255, 0.15);
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  position: relative;
}

.fileContainer .deleteImage {
  display: none !important;
}

.fileContainer .deleteImage:after {
  content: "Delete image";
  position: absolute;
  right: -45px;
  font-size: 16px;
}

.fileContainer .uploadPictureContainer img.uploadPicture {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.fileContainer .errorsContainer {
  max-width: 300px;
  font-size: 12px;
  position: absolute;
  z-index: 7;
  top: 35%;
  background: rgba(255, 0, 0, 0.7);
  padding: 10px;
  text-align: center;
  color: white;
  margin: 0 auto;
  width: 79%;
  border-radius: 6px;
}

.fileContainer .errorsContainer:empty {
  opacity: 0;
}

.fileContainer .chooseFileButton {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 50% 23%;
  background-size: 25%;
  border-radius: 0;
  color: white;
  font-weight: 300;
  font-size: 14px;
  transition: all 0.2s ease-in;
  cursor: pointer;
  outline: none;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: block;
  margin: 0;
  width: 100%;
  z-index: 9;
}

.fileContainer .chooseFileButton:hover {
  /*background-image: url(./icon_upload.svg);*/
  background-color: rgb(24, 24, 24, 0.56);
  background-repeat: no-repeat;
  background-position: 50% 23%;
  background-size: 25%;
}
